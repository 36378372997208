/* App.css */

.App {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

header {
  margin-bottom: 40px;
}

h1 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 10px;
}

section {
  margin-bottom: 40px;
}

h2 {
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 20px;
}

p {
  color: #555;
  font-size: 1.2rem;
  line-height: 1.6;
}

a {
  color: #007bff;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 20px;
}

h3 {
  color: #333;
  font-size: 1.4rem;
  margin-bottom: 10px;
}

footer {
  margin-top: 50px;
  color: #777;
  font-size: 0.9rem;
}
